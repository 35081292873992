<script setup lang="ts">
import { useOnline } from '@vueuse/core';

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.');
  } else {
    dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
  }
});

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} | Medmastery` : 'Medmastery';
  },
});
</script>

<template>
  <NuxtLayout>
    <SystemMessages />
    <DynamicDialog />

    <NuxtPage />
  </NuxtLayout>
</template>
