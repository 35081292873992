/** @type {import('tailwindcss').Config} */
// eslint-disable-next-line no-undef
export const content = [
  "./components/**/*.{js,vue,ts}",
  "./layouts/**/*.vue",
  "./pages/**/*.vue",
  "./plugins/**/*.{js,ts}",
  "./app.vue",
  "./error.vue",
  "./mui/src/components/**/*.{js,vue,ts}",
];

export const theme = {
  screens: {
    'sm': '640px',
    'md': '767px', // ????
    'lg': '992px',
    'xl': '1260px',
    '2xl': '2048px',
    'md-h': { 'raw': '(max-height: 760px)' },
    'md-h-reverse': { 'raw': '(min-height: 760px)' },
    'lg-h': { 'raw': '(max-height: 880px)' },
  },
  container: {
    center: true,
    padding: {
      DEFAULT: '1rem',
      sm: '2rem',
      lg: '4rem',
      xl: '5rem',
      '2xl': '6rem',
    },
  },
  colors: {
    transparent: 'transparent',
    current: 'currentColor',
    black: 'black',
    white: 'white',
    neutral: {
      100: 'rgba(16, 42, 67, 1)',
      90: 'rgba(36, 59, 83, 1)',
      80: 'rgba(51, 78, 104, 1)',
      70: 'rgba(71, 100, 128, 1)',
      60: 'rgba(94, 120, 146, 1)',
      50: 'rgba(130, 154, 177, 1)',
      40: 'rgba(159, 179, 200, 1)',
      30: 'rgba(205, 217, 229, 1)',
      20: 'rgba(228, 234, 241, 1)',
      10: 'rgba(241, 245, 249, 1)',
      5: 'rgba(245, 247, 250, 1)',
    },
    blue: {
      100: 'rgba(0, 71, 107, 1)',
      90: 'rgba(4, 96, 143, 1)',
      80: 'rgba(8, 108, 160, 1)',
      70: 'rgba(14, 127, 185, 1)',
      60: 'rgba(22, 142, 204, 1)',
      50: 'rgba(58, 167, 223, 1)',
      40: 'rgba(88, 190, 243, 1)',
      30: 'rgba(127, 208, 250, 1)',
      20: 'rgba(182, 228, 251, 1)',
      10: 'rgba(227, 244, 252, 1)',
    },
    yellow: {
      100: 'rgba(151, 59, 2, 1)',
      90: 'rgba(174, 86, 4, 1)',
      80: 'rgba(191, 130, 8, 1)',
      70: 'rgba(215, 162, 15, 1)',
      60: 'rgba(242, 182, 13, 1)',
      50: 'rgba(249, 204, 57, 1)',
      40: 'rgba(251, 224, 101, 1)',
      30: 'rgba(253, 233, 145, 1)',
      20: 'rgba(255, 244, 194, 1)',
      10: 'rgba(255, 250, 229, 1)',
    },
    green: {
      100: 'rgba(34, 100, 18, 1)',
      90: 'rgba(21, 131, 17, 1)',
      80: 'rgba(39, 152, 31, 1)',
      70: 'rgba(80, 180, 87, 1)',
      60: 'rgba(83, 198, 99, 1)',
      50: 'rgba(105, 216, 122, 1)',
      40: 'rgba(125, 227, 152, 1)',
      30: 'rgba(160, 238, 181, 1)',
      20: 'rgba(197, 252, 212, 1)',
      10: 'rgba(235, 255, 241, 1)',
    },
    red: {
      100: 'rgba(107, 0, 0, 1)',
      90: 'rgba(143, 4, 4, 1)',
      80: 'rgba(160, 8, 8, 1)',
      70: 'rgba(185, 14, 14, 1)',
      60: 'rgba(204, 22, 22, 1)',
      50: 'rgba(223, 58, 58, 1)',
      40: 'rgba(243, 88, 88, 1)',
      30: 'rgba(250, 127, 127, 1)',
      20: 'rgba(251, 182, 182, 1)',
      10: 'rgba(252, 227, 227, 1)',
    },
  },
  fontSize: {
    '48': "3rem",
    '36': "2.25rem",
    '32': "2rem",
    '28': "1.75rem",
    '24': "1.5rem",
    '20': "1.25rem",
    '22': "1.375rem",
    '18': "1.125rem",
    '16': "1rem",
    '14': "0.875rem",
    '12': "0.75rem",
  },
  lineHeight: {
    'normal': 'normal',
    '100': '100%',
    '130': '130%',
    '150': '150%',
  },
  extend: {
    boxShadow: {
      'medmastery': '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
      'medmastery-hard': '0px 2px 16px 0px rgba(0, 0, 0, 0.2)',
      'medmastery-light': '0px 5px 10px 0px rgba(159, 179, 200, 0.05), 0px 15px 40px 0px rgba(98, 125, 152, 0.20)',
    },
    backgroundImage: {
      'subscription-card-thumbnail': "url('@/assets/images/backgrounds/subscription-card-thumbnail.png')",
      'subscription-card-thumbnail-mobile': "url('@/assets/images/backgrounds/subscription-card-thumbnail-mobile.svg')",
    },
    scrollPadding: {
      '16': '4rem',
    },
  },
  keyframes: {
    scroll: {
      '0%': { transform: 'translateY(-0.1rem)' },
      '50%': { transform: 'translateY(0.1rem)' },
      '100%': { transform: 'translateY(-0.1rem)' },
    },
    swipe: {
      '0%': { transform: 'translateY(0) rotateX(0deg)' },
      '25%': { transform: 'translateY(0) rotateX(-180deg)' },
      '50%': { transform: 'translateY(0) rotateX(0deg)' },
      '75%': { transform: 'translateY(0) rotateX(180deg)' },
      '100%': { transform: 'translateY(0) rotateX(0deg)' },
    },
    gradient: {
      '0%': { 'background-position': '0% 0%' },
      '100%': { 'background-position': '100% 0%' },
    },
    fillHorizontal: {
      '0%': { 'width': '0' },
      '100%': { 'width': '100%' },
    },
    spin: {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    heartbeat: {
      '0%': { transform: 'scale(1)', opacity: '1' },
      '30%': { transform: 'scale(1.3)', opacity: '0.8' },
      '50%': { transform: 'scale(1)', opacity: '1' },
      '70%': { transform: 'scale(0.9)', opacity: '0.9' },
      '100%': { transform: 'scale(1)', opacity: '1' },
    },
  },
  animation: {
    scroll: 'scroll 2s ease-in-out infinite',
    swipe: 'swipe 5s ease-in-out infinite',
    gradient: 'gradient 1s linear infinite',
    fillHorizontal: 'fillHorizontal 8s linear',
    spin: 'spin 2s linear infinite',
    heartbeat: 'heartbeat 4s ease-in-out infinite',
  },
};

export const plugins = [];

